import React from 'react';
import Tools from './Tools';

const searchFilter = (text,pos,sep,extFilter) => {
  return (
    (row,index) => {
      if (text==="") return false;
      if (text==="*") text=" ";
      let itemval=(" "+row.split(sep)[pos]).toLowerCase();
      let val=(" "+text).toLowerCase();
      if (!extFilter) return (text!==" " && itemval.indexOf(val) !== -1 );
      // extendedFilter for products
      if ( text!==" " && itemval.indexOf(val) === -1 ) return false;
      let item=row.split(sep);
      let ret=(item.length>7);
      if (!ret) return false;
      ret=Tools.selected["on"]["TagPais"].size===0 || Tools.selected["on"]["TagPais"].has(item[2]) || (item[2]!==item[3] && Tools.selected["on"]["TagPais"].has(item[3]));
      let tmp1=null, tmp2=null;
      if (!ret ) { 
        tmp1=item[2].split("_");tmp2=item[3].split("_");
        ret=(tmp1.length>1||tmp2.length>1)&& (Tools.selected["on"]["TagPais"].has(tmp1[0]) || (item[2]!==item[3] && Tools.selected["on"]["TagPais"].has(tmp2[0])) );
      };
      if (!ret ) return false;
      ret=Tools.selected["no"]["TagPais"].size===0 || !(Tools.selected["no"]["TagPais"].has(item[2]) || (item[2]!==item[3] && Tools.selected["no"]["TagPais"].has(item[3])));
      if (!ret ) { 
        if (tmp1===null) {tmp1=item[2].split("_");tmp2=item[3].split("_");}
        ret=(tmp1.length>1||tmp2.length>1)&& !(Tools.selected["no"]["TagPais"].has(tmp1[0]) || (item[2]!==item[3] && Tools.selected["no"]["TagPais"].has(tmp2[0])) );
      };
      if (!ret) return false;
      ret= (Tools.selected["on"]["TagCategoria"].size===0);
      let len=0;
      let catid="";
      while (len<4 && item[4+len]!=="" && !ret) {
        catid=catid+"."+item[4+len];
        ret= Tools.selected["on"]["TagCategoria"].has(len.toString()+catid) ;
        len++;
      }
      //console.log("prod:"+item[1]+" c:"+item[4]+" ret:"+ret+" len:"+len);
      if (!ret) return false;
      ret= (Tools.selected["no"]["TagCategoria"].size===0);
      len=0;
      catid="";
      while (len<4 && item[4+len]!=="" && !ret) {
        catid=catid+"."+item[4+len];
        ret= Tools.selected["no"]["TagCategoria"].has(len.toString()+catid) ;
        len++;
      }
      return (len===0 || !ret) ;
    }
  )
}
const searchSelected = (name,cat,sep) => {
  return (
    (row,index) => {
      let item=row.split(sep);
      let id= (name!=="TagCategoria")?item[0]:item[0]+"."+item[1];      
      return ( Tools.selected[cat][name].has(id) );
    }
  )
}
const tagIcon = (values) => <div style={{width:'17px',}}><img className="TagIcon" src={values[1]} alt="Icono"/></div> ;
const flag = (item) => <div style={{width:'27px',}}><img className={"TagFlag flag flag-"+item[0]} src="css/flags/blank.gif" alt="bandera"/></div> ;
//const hasTags = () => Tools.selected.on.length===0 && Tools.selected.no.length===0;
const renderObject = (name,values,sep) => {
  return (
    (row, index) => {
      let item=row.split(sep);
      let id= (name!=="TagCategoria")?item[0]:item[0]+"."+item[1];      
      return (
        <div className={name} style={{border: values[2],}} key={id} data_id={id} data_toogle={values[0]} 
            ref={ (c) => {if (c) c.onclick = () => Tools.clickToogle(c,id,values[0]);} }>
            {tagIcon(values)}
            {name==="TagPais"?flag(item):null}
            <div className="Text" >
              {name!=="TagCategoria"?item[1]:item[2]}
            </div>
            <div style={{width:'1px',}}></div>
        </div>
      );
    }
  );
}

class TagsBuscadorContainer extends React.Component {
  state ={};//searchText:"",

  countries = [];
  categories = [];
  products = [];
  onCountries=[];
  noCountries=[];
  onCategories=[];
  noCategories=[];
  onProducts=[];
  noProducts=[];

  values=["off","imgs/icons/add-icon.svg","",""];
  valuesOn=["on","imgs/icons/check-icon.svg","2px solid #EEEE1F","block"];
  valuesNo=["no","imgs/icons/uncheck-icon-red.svg","2px solid #EEEE1F", "block"];
  render(){
    //if (..searchText!==...state.searchText) {..
    this.onCountries = Tools.countriesResponse.filter(
      searchSelected("TagPais","on","-")).map(renderObject("TagPais",this.valuesOn,"-"));
    this.noCountries = Tools.countriesResponse.filter(
      searchSelected("TagPais","no","-")).map(renderObject("TagPais",this.valuesNo,"-"));
    this.onCategories = Tools.categoriesResponse.filter(
      searchSelected("TagCategoria","on","-")).map(renderObject("TagCategoria",this.valuesOn,"-"));
    this.noCategories = Tools.categoriesResponse.filter(
      searchSelected("TagCategoria","no","-")).map(renderObject("TagCategoria",this.valuesNo,"-"));
    this.onProducts = Tools.productsResponse.filter(
      searchSelected("TagProducto","on","|")).map(renderObject("TagProducto",this.valuesOn,"|"));
    this.noProducts = Tools.productsResponse.filter(
      searchSelected("TagProducto","no","|")).map(renderObject("TagProducto",this.valuesNo,"|"));
    this.countries = Tools.countriesResponse.filter(
      searchFilter(this.props.searchText,1,"-",false)).map(renderObject("TagPais",this.values,"-"));
    this.categories = Tools.categoriesResponse.filter(
      searchFilter(this.props.searchText,2,"-",false)).map(renderObject("TagCategoria",this.values,"-"));
    this.products = Tools.productsResponse.filter(
      searchFilter(this.props.searchText,1,"|",true)).map(renderObject("TagProducto",this.values,"|"));
    return ( 
      <div className="TagsBuscadorContainer">
          {this.onCountries}{this.noCountries}
          {this.onCategories}{this.noCategories}
          {this.onProducts}{this.noProducts}
        {this.countries}
        {this.categories}
        {this.products}
      </div>
    );
    
  }
}

export default TagsBuscadorContainer;