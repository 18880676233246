// Defining Common object
let Tools={};

// Animation Routines

Tools.changeProperty = (node, property,unit,ini, fin,step) => {
  // node to move, name of property to change, unit ('px','%',..),
  let x=ini;//window.getComputedStyle(React.findDOMNode(node)).getPropertyValue("top");
  const id = setInterval(moveProp, 40); //40ms +- 25fps
  function moveProp(){
      node.style[property]=x+unit;
      if (x-fin<=0) {clearInterval(id);node.style[property]=fin+unit}
      else {x+=step;}
  }
}
// Search routines
Tools.test="Tools.test";
Tools.countriesResponse = [];
Tools.categoriesResponse = [];
Tools.productsResponse=[];
Tools.productsSelected=[];
Tools.email="am@cartana.es";
Tools.password="Cartaña";
Tools.userConfig=null;
Tools.logged=false;
Tools.serverUrl="http://bazzadvisor.com/";//"./";
//Tools.serverUrl="http://www.front.2624895-1.web-hosting.es/";//"./";
//Tools.serverUrl="http://localhost/baazup-classic/";
//Tools.serverUrl="./";
Tools.importAll = () => {
  if (Tools.countriesResponse.length===0) Tools.getAllCountries();
  if (Tools.categoriesResponse.length===0) Tools.getAllCategories();
  if (Tools.productsResponse.length===0) Tools.getAllProducts();
}
Tools.getAllCountries = () => {
  const xhttp= new XMLHttpRequest();
  Tools.countriesResponse=["hello"];
  xhttp.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
      Tools.countriesResponse=this.responseText.split("#");
    }
    else Tools.countriesResponse=["waiting-a-response-"+this.readyState];
  };
  xhttp.open("GET", Tools.serverUrl+"services/db_allCountries.php", true);
  xhttp.send();
}
Tools.getAllCategories = () => {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
   if (this.readyState === 4 && this.status === 200) {
       Tools.categoriesResponse=this.responseText.split("#");
   }
  };
  xhttp.open("GET", Tools.serverUrl+"services/db_allCategories.php", true);
  xhttp.send();
}
Tools.getAllProducts = () => {
   const xhttp = new XMLHttpRequest();
   xhttp.onreadystatechange = function() 
   {
     if (this.readyState === 4 && this.status === 200) 
     {
      Tools.productsResponse=this.responseText.split("#");
     }
   };
   xhttp.open("GET", Tools.serverUrl+"services/db_allProducts.php", true);
   xhttp.send();
}
Tools.getSelProducts = (cb=undefined) => {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() 
  {
    if (this.readyState === 4 && this.status === 200) 
    {
     Tools.productsSelected=this.responseText.split("#");
     if (typeof cb==="function") cb();
    }
  };
  console.log("adress ids:"+(Array.from(Tools.selected["on"]["TagProducto"]).join(",")) ) ;
  xhttp.open("GET", Tools.serverUrl+"services/db_selProduct.php?codigos="+Array.from(Tools.selected.on.TagProducto).join(","), true);
  xhttp.send();
}
Tools.getUserConfig = (cb=undefined) => {
  const xhttp = new XMLHttpRequest();
  if (xhttp.withCredentials===false ) console.log("no fucked"); else console.log("fucked");
  xhttp.onreadystatechange = function() 
  {
    if (this.readyState === 4 && this.status === 200) 
    {
     Tools.userConfig=this.responseText.split("#");
     Tools.logged=(Tools.userConfig!==null && Tools.userConfig[0].indexOf("error") === -1);
     console.log("userConfig:"+Tools.userConfig[0]);
     if (typeof cb==="function") cb();
     console.log("background:"+(Tools.userConfig[0].indexOf("error") ));
    }
    //else console.log("res:"+this.responseText);
  };
  let mail=document.querySelector("#inputMail").value;
  let password=document.querySelector("#inputPassword").value;
  Tools.email=mail;
  Tools.password=password;
  let data="user="+Tools.email+","+Tools.password+","+Tools.hex_md5(Tools.password);//"foo=bar&lorem=ipsum"
  console.log("send adres:"+Tools.serverUrl+"services/db_userConfig.php post=>"+data);
  console.log("password:"+Tools.hex_md5(Tools.password));
  xhttp.open("POST", Tools.serverUrl+"services/db_userConfigPost.php");
  xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhttp.send(data);
}

Tools.background = () => Tools.logged;
Tools.registerUser = (cb=undefined) => {
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function() 
  {
    if (this.readyState === 4 && this.status === 200) 
    {
     let temp=this.responseText.split("#");
     console.log("registerUser:"+temp);
     if (typeof cb==="function") cb();
    }
  };
  let mail=document.querySelector("#inputMail").value;
  let password=document.querySelector("#inputPassword").value;
  Tools.email=mail;
  Tools.password=password;
  let data="user="+Tools.email+","+Tools.password+","+Tools.hex_md5(Tools.password);//"foo=bar&lorem=ipsum"
  console.log("send adress:"+Tools.serverUrl+"services/db_regUser.php post=>"+data)
  xhttp.open("POST", Tools.serverUrl+"services/db_regUserPost.php");
  xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhttp.send(data);
}

 /* CALLBACK ON SEARCHBOX WRITING */

Tools.searchText="";//  
Tools.timeOut=false;
Tools.searchTags = (func ) => {
	Tools.searchText =document.querySelector("#inputBuscador").value;
  if (Tools.timeOut) clearTimeout(Tools.timeOut);
	Tools.timeOut =	setTimeout(() =>{func(Tools.searchText)}, 700);
}

/* TOOGLE SELECT TAG */

Tools.selected={
  'on':{'TagPais':new Set(),'TagCategoria':new Set(),'TagProducto':new Set(),},
  'no':{'TagPais':new Set(),'TagCategoria':new Set(),'TagProducto':new Set(),},
}
Tools.clickToogle = (node,id,data_toogle) => {
  //Aplicar y quitar estilos en toggle
  //Cambiar img:first-of-type de add-icon a check-icono
  //let values=["","","",""];
  //data_toogle is a personal atribute only works on html5 browsers
  //if(!this.hasAttribute("data_toogle")) this.setAttribute("data_toogle","off");
  let values;
  if (node===null) return;
  if (!(node.data_toogle)) node.data_toogle=data_toogle;
  if (node.data_toogle !== "off") Tools.selected[node.data_toogle][node.className].delete(id);
  if ((node.data_toogle==="off")){
    values=["on","imgs/icons/check-icon.svg","2px solid #EEEE1F","block"];
  } else if (node.data_toogle==="on" && node.className!=="TagProducto"){
    values=["no","imgs/icons/uncheck-icon-red.svg","2px solid #EEEE1F", "block"];
  } else {
    values=["off","imgs/icons/add-icon.svg","",""];
  }
  node.data_toogle=values[0];
  node.querySelector(".TagIcon").src=values[1];
  //Añadir borde blanco
  node.style.border=values[2];
  if (node.data_toogle !== "off") Tools.selected[node.data_toogle][node.className].add(id);
  console.log ( "data_id"+id);
  //Añadir boton de ver resultados
  let doc=document.querySelector(".VerResultados");
  let numproducts=Tools.selected.on.TagProducto.size;
  doc.style.display= numproducts > 0 ? "block" : "none";
  document.querySelector("span").innerHTML=numproducts;
}


/** *********************MD5 ************* */
/*
 * A JavaScript implementation of the RSA Data Security, Inc. MD5 Message
 * Digest Algorithm, as defined in RFC 1321.
 * Version 2.2 Copyright (C) Paul Johnston 1999 - 2009
 * Other contributors: Greg Holt, Andrew Kepert, Ydnar, Lostinet
 * Distributed under the BSD License
 * See http://pajhome.org.uk/crypt/md5 for more info.
 */

/*
 * Configurable variables. You may need to tweak these to be compatible with
 * the server-side, but the defaults work in most cases.
 */
let hexcase = 0;   /* hex output format. 0 - lowercase; 1 - uppercase        */

/*
 * These are the functions you'll usually want to call
 * They take string arguments and return either hex or base-64 encoded strings
 */
Tools.hex_md5= (s) =>   { return rstr2hex(rstr_md5(str2rstr_utf8(s))); }


/*
 * Calculate the MD5 of a raw string
 */
function rstr_md5(s)
{
  return binl2rstr(binl_md5(rstr2binl(s), s.length * 8));
}

/*
 * Calculate the HMAC-MD5, of a key and some data (raw strings)
 */
/*
 * Convert a raw string to a hex string
 */
function rstr2hex(input)
{
  //try { let a = typeof hexcase } catch(e) { hexcase=0; }
  let hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
  let output = "";
  let x;
  for(let i = 0; i < input.length; i++)
  {
    x = input.charCodeAt(i);
    output += hex_tab.charAt((x >>> 4) & 0x0F)
           +  hex_tab.charAt( x        & 0x0F);
  }
  return output;
}

/*
 * Convert a raw string to a base-64 string
 */
function str2rstr_utf8(input)
{
  let output = "";
  let i = -1;
  let x, y;

  while(++i < input.length)
  {
    /* Decode utf-16 surrogate pairs */
    x = input.charCodeAt(i);
    y = i + 1 < input.length ? input.charCodeAt(i + 1) : 0;
    if(0xD800 <= x && x <= 0xDBFF && 0xDC00 <= y && y <= 0xDFFF)
    {
      x = 0x10000 + ((x & 0x03FF) << 10) + (y & 0x03FF);
      i++;
    }

    /* Encode output as utf-8 */
    if(x <= 0x7F)
      output += String.fromCharCode(x);
    else if(x <= 0x7FF)
      output += String.fromCharCode(0xC0 | ((x >>> 6 ) & 0x1F),
                                    0x80 | ( x         & 0x3F));
    else if(x <= 0xFFFF)
      output += String.fromCharCode(0xE0 | ((x >>> 12) & 0x0F),
                                    0x80 | ((x >>> 6 ) & 0x3F),
                                    0x80 | ( x         & 0x3F));
    else if(x <= 0x1FFFFF)
      output += String.fromCharCode(0xF0 | ((x >>> 18) & 0x07),
                                    0x80 | ((x >>> 12) & 0x3F),
                                    0x80 | ((x >>> 6 ) & 0x3F),
                                    0x80 | ( x         & 0x3F));
  }
  return output;
}


/*
 * Convert a raw string to an array of little-endian words
 * Characters >255 have their high-byte silently ignored.
 */
function rstr2binl(input)
{
  let output = Array(input.length >> 2);
  for(let i = 0; i < output.length; i++)
    output[i] = 0;
  for(let i = 0; i < input.length * 8; i += 8)
    output[i>>5] |= (input.charCodeAt(i / 8) & 0xFF) << (i%32);
  return output;
}

/*
 * Convert an array of little-endian words to a string
 */
function binl2rstr(input)
{
  let output = "";
  for(let i = 0; i < input.length * 32; i += 8)
    output += String.fromCharCode((input[i>>5] >>> (i % 32)) & 0xFF);
  return output;
}

/*
 * Calculate the MD5 of an array of little-endian words, and a bit length.
 */
function binl_md5(x, len)
{
  /* append padding */
  x[len >> 5] |= 0x80 << ((len) % 32);
  x[(((len + 64) >>> 9) << 4) + 14] = len;

  let a =  1732584193;
  let b = -271733879;
  let c = -1732584194;
  let d =  271733878;

  for(let i = 0; i < x.length; i += 16)
  {
    let olda = a;
    let oldb = b;
    let oldc = c;
    let oldd = d;

    a = md5_ff(a, b, c, d, x[i+ 0], 7 , -680876936);
    d = md5_ff(d, a, b, c, x[i+ 1], 12, -389564586);
    c = md5_ff(c, d, a, b, x[i+ 2], 17,  606105819);
    b = md5_ff(b, c, d, a, x[i+ 3], 22, -1044525330);
    a = md5_ff(a, b, c, d, x[i+ 4], 7 , -176418897);
    d = md5_ff(d, a, b, c, x[i+ 5], 12,  1200080426);
    c = md5_ff(c, d, a, b, x[i+ 6], 17, -1473231341);
    b = md5_ff(b, c, d, a, x[i+ 7], 22, -45705983);
    a = md5_ff(a, b, c, d, x[i+ 8], 7 ,  1770035416);
    d = md5_ff(d, a, b, c, x[i+ 9], 12, -1958414417);
    c = md5_ff(c, d, a, b, x[i+10], 17, -42063);
    b = md5_ff(b, c, d, a, x[i+11], 22, -1990404162);
    a = md5_ff(a, b, c, d, x[i+12], 7 ,  1804603682);
    d = md5_ff(d, a, b, c, x[i+13], 12, -40341101);
    c = md5_ff(c, d, a, b, x[i+14], 17, -1502002290);
    b = md5_ff(b, c, d, a, x[i+15], 22,  1236535329);

    a = md5_gg(a, b, c, d, x[i+ 1], 5 , -165796510);
    d = md5_gg(d, a, b, c, x[i+ 6], 9 , -1069501632);
    c = md5_gg(c, d, a, b, x[i+11], 14,  643717713);
    b = md5_gg(b, c, d, a, x[i+ 0], 20, -373897302);
    a = md5_gg(a, b, c, d, x[i+ 5], 5 , -701558691);
    d = md5_gg(d, a, b, c, x[i+10], 9 ,  38016083);
    c = md5_gg(c, d, a, b, x[i+15], 14, -660478335);
    b = md5_gg(b, c, d, a, x[i+ 4], 20, -405537848);
    a = md5_gg(a, b, c, d, x[i+ 9], 5 ,  568446438);
    d = md5_gg(d, a, b, c, x[i+14], 9 , -1019803690);
    c = md5_gg(c, d, a, b, x[i+ 3], 14, -187363961);
    b = md5_gg(b, c, d, a, x[i+ 8], 20,  1163531501);
    a = md5_gg(a, b, c, d, x[i+13], 5 , -1444681467);
    d = md5_gg(d, a, b, c, x[i+ 2], 9 , -51403784);
    c = md5_gg(c, d, a, b, x[i+ 7], 14,  1735328473);
    b = md5_gg(b, c, d, a, x[i+12], 20, -1926607734);

    a = md5_hh(a, b, c, d, x[i+ 5], 4 , -378558);
    d = md5_hh(d, a, b, c, x[i+ 8], 11, -2022574463);
    c = md5_hh(c, d, a, b, x[i+11], 16,  1839030562);
    b = md5_hh(b, c, d, a, x[i+14], 23, -35309556);
    a = md5_hh(a, b, c, d, x[i+ 1], 4 , -1530992060);
    d = md5_hh(d, a, b, c, x[i+ 4], 11,  1272893353);
    c = md5_hh(c, d, a, b, x[i+ 7], 16, -155497632);
    b = md5_hh(b, c, d, a, x[i+10], 23, -1094730640);
    a = md5_hh(a, b, c, d, x[i+13], 4 ,  681279174);
    d = md5_hh(d, a, b, c, x[i+ 0], 11, -358537222);
    c = md5_hh(c, d, a, b, x[i+ 3], 16, -722521979);
    b = md5_hh(b, c, d, a, x[i+ 6], 23,  76029189);
    a = md5_hh(a, b, c, d, x[i+ 9], 4 , -640364487);
    d = md5_hh(d, a, b, c, x[i+12], 11, -421815835);
    c = md5_hh(c, d, a, b, x[i+15], 16,  530742520);
    b = md5_hh(b, c, d, a, x[i+ 2], 23, -995338651);

    a = md5_ii(a, b, c, d, x[i+ 0], 6 , -198630844);
    d = md5_ii(d, a, b, c, x[i+ 7], 10,  1126891415);
    c = md5_ii(c, d, a, b, x[i+14], 15, -1416354905);
    b = md5_ii(b, c, d, a, x[i+ 5], 21, -57434055);
    a = md5_ii(a, b, c, d, x[i+12], 6 ,  1700485571);
    d = md5_ii(d, a, b, c, x[i+ 3], 10, -1894986606);
    c = md5_ii(c, d, a, b, x[i+10], 15, -1051523);
    b = md5_ii(b, c, d, a, x[i+ 1], 21, -2054922799);
    a = md5_ii(a, b, c, d, x[i+ 8], 6 ,  1873313359);
    d = md5_ii(d, a, b, c, x[i+15], 10, -30611744);
    c = md5_ii(c, d, a, b, x[i+ 6], 15, -1560198380);
    b = md5_ii(b, c, d, a, x[i+13], 21,  1309151649);
    a = md5_ii(a, b, c, d, x[i+ 4], 6 , -145523070);
    d = md5_ii(d, a, b, c, x[i+11], 10, -1120210379);
    c = md5_ii(c, d, a, b, x[i+ 2], 15,  718787259);
    b = md5_ii(b, c, d, a, x[i+ 9], 21, -343485551);

    a = safe_add(a, olda);
    b = safe_add(b, oldb);
    c = safe_add(c, oldc);
    d = safe_add(d, oldd);
  }
  return [a, b, c, d];//Array(a, b, c, d);
}

/*
 * These functions implement the four basic operations the algorithm uses.
 */
function md5_cmn(q, a, b, x, s, t)
{
  return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s),b);
}
function md5_ff(a, b, c, d, x, s, t)
{
  return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t);
}
function md5_gg(a, b, c, d, x, s, t)
{
  return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t);
}
function md5_hh(a, b, c, d, x, s, t)
{
  return md5_cmn(b ^ c ^ d, a, b, x, s, t);
}
function md5_ii(a, b, c, d, x, s, t)
{
  return md5_cmn(c ^ (b | (~d)), a, b, x, s, t);
}

/*
 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
 * to work around bugs in some JS interpreters.
 */
function safe_add(x, y)
{
  let lsw = (x & 0xFFFF) + (y & 0xFFFF);
  let msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return (msw << 16) | (lsw & 0xFFFF);
}

/*
 * Bitwise rotate a 32-bit number to the left.
 */
function bit_rol(num, cnt)
{
  return (num << cnt) | (num >>> (32 - cnt));
}

export default Tools; 



