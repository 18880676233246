import React from 'react';

function initialAnimation(node,callback) {
  //var node=document.querySelector(".InitAnimation");;
  const worldBall=document.createElement("IMG");
  const worldText=document.createElement("IMG");
  const worldMagnify=document.createElement("IMG");
  worldBall.src="imgs/logos/world.svg";
  worldText.src="imgs/logos/texto.svg";
  worldMagnify.src="imgs/logos/lupa.svg";
  //elem.style="width:100px; heigth:100px; position:absolute;";
  node.appendChild(worldBall);
  node.appendChild(worldText);
  node.appendChild(worldMagnify);
  const parentInfo = node.parentNode.getBoundingClientRect();
  const relatSize=0.115;
  var baseSize=parentInfo.width*relatSize;
  var baseSizeRatio=parentInfo.width/parentInfo.height;
  worldBall.style="width:"+baseSize+"px; heigth:"+baseSize+
           "px; position:absolute; left:0px; top:0px;";
  worldText.style="width:"+baseSize+"px; heigth:"+baseSize+
           "px; position:absolute; left:"+(parentInfo.width/2- baseSize/2)+
           "px; top:0px; opacity:0;";
  worldMagnify.style="width:"+baseSize+"px; heigth:"+baseSize+
           "px; position:absolute; left:"+(parentInfo.width/2- baseSize/2)+
           "px; top:"+(parentInfo.height/6 - baseSize/2)+"px; opacity:0;";
  const  posFin = 0.5, frameRate=1/25;
  let x =-0.5, y=0, fase=0;
  const id = setInterval(frameBall, 40);

  function world(elem){
    if (x > posFin) {
      fase=1;x=-0.5;
   } else {
      y=3.33*x*x-0.33*x; // -1/2->1,+1/2->1/3, 0->0
      elem.style.top = (parentInfo.height/2)*(1-y-baseSizeRatio*(1+x)*relatSize) + 'px'; 
      elem.style.left = (parentInfo.width/2)*(1/2+x-(1+x)*relatSize) + 'px'; 
      elem.style.width = baseSize*(1+x) + 'px';
      elem.style.height = baseSize*(1+x) + 'px';
      x+=frameRate; 
    }
  }
  function text(elem){
    if (x > posFin) {
      fase=2;x=-0.5;
    } else {
      y=(x+0.45); 
      elem.style.top = (parentInfo.height*y/6 - baseSize/2) + 'px'; 
      elem.style.opacity = y;
      x+=frameRate*2; 
    }
  }
  function magnify(elem){
    if (x > posFin) {
      fase=3;x=-0.5;
      clearInterval(id);
      callback();
    } else {
      y=(x+0.5); 
      elem.style.opacity=y;
      x+=frameRate; 
    }
  }

  function frameBall() {
    switch (fase){
      case 0: world(worldBall);break;
      case 1: text(worldText);break;
      case 2: magnify(worldMagnify);break;
      default : break;
    }
  }
};
class InitAnimation extends React.Component {
  //const InitAnimation = (props) => {
   
   componentDidMount(){initialAnimation(this.node,this.props.handler);}
   render(){
    return (
      <div className="InitAnimation" onClick={this.props.handler} ref={c => (this.node = c)}>
      </div>
    );
   }
}

export default InitAnimation;