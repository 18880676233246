import React from 'react';
import Tools from './Tools'

//import Tools from './Tools';

class Search extends React.Component {
    //const InitAnimation = (props) => {
    //const divStyle = (page) =>page===0?{}:{ top: '5%' };
    state ={top:true,};
    node=null;
    componentDidMount(){
        if (this.props.page===2 && this.state.top) {
            this.setState({top:false});
            //let val=window.getComputedStyle(this.refs.Search).getPropertyValue("top");
            Tools.changeProperty(this.node,'top',"%",40,5,-5);
        }
    }
    delText = () => { document.querySelector("#inputBuscador").value=""; }
    render(){
        if (this.props.page===1) 
        return (
         <div className="Search">
           <input id="inputBuscador" type="text" placeholder="Buscar" onClick={this.props.handler}/> 
           <img className="searchIcon" src="imgs/icons/search-icon.svg" alt="icono de busqueda" onClick={this.props.handler}/>
         </div>
        );
        else //style={{ top: '5%' }} c => (this.node = c)
        return (
         <div className="Search" ref={nd => this.node=nd} >
           <input autoFocus id="inputBuscador" type="text" placeholder="Buscar" onChange={() => this.props.handler()}/> 
           <img className="uncheckIcon" src="imgs/icons/uncheck-icon-red.svg" alt="icono de borrado" onClick={this.delText}/>
           <img className="searchIcon" src="imgs/icons/search-icon.svg" alt="icono de busqueda" onClick={() => this.props.handler(true)}/>
         </div>
        );
     }
}

export default Search;