import React from 'react';
import InitAnimation from './InitAnimation';
import Search from './Search';
import TagsBuscadorContainer from './TagsBuscadorContainer';
import Resultados from './Resultados';
import Login from './Login'
import Tools from './Tools';

const Logo = () => { 
  return (
      <div className="Logo TopCenter">
        <img className="CenterImage" src="imgs/logos/baazup3.svg" alt="logo baazap" />
      </div>
  );
}

const BuscadorOverlay = () => (<div className="BuscadorOverlay Overlay1"></div>)

const Lema = (props) => { 
  return (
    <div className="Lema">
      <p>Descubre las banderas de tus compras.</p>
      <p style={{color:"red",}} onClick={props.handler}><img src="imgs/logos/OpenBook.svg" alt="Acceso al tutorial"></img></p>
    </div>
  );
}
const Manual = (props) => { 
  if (props.show)
    return (
      <div className="Manual">
        <img src="imgs/icons/uncheck-icon-red.svg" onClick={props.handler} alt="Acceso al tutorial"></img>
        <h1>Manual Bazzadvisor</h1>
        <p>La aplicacion permite buscar productos por diferentes categorias.</p>
        <p>Para buscar un producto basta con  comenzar a escribir el nombre de este o una categoria a la que pertenezca para que te salga una lista de selección.</p>
        <p>Funcionamiento de la entrada del buscador</p>
        <ul>
          <li>introduccion de texto: en cuanto se para un momento de escribir la aplicacion busca todas las categorias/paises/productos que contienen dicho texto</li>
          <li>la cruz: borra el texto</li>
          <li>la lupa: busca todos los productos que cumplen las categorías/paises seleccionados</li>
        </ul>
        <p>En la lista de selección al pulsar en una categoría o país esta pasará por los siguientes estados:</p>
        <ul>
          <li>afirmativa: los productos buscados tienen que pertenecer a esta categoría/país</li>
          <li>negativa: los productos buscados no pueden pertenecer a esta categoría/país</li>
          <li>desactivada: los productos buscados no se ven afectados por esta categoría/país</li>
        </ul>
      </div>
    );
  else
   return(null);
}
//      <a href={Tools.serverUrl+"resultados.html"}>
//      </a>

const VerResultados = (props) => {
  return (
    <div className="VerResultados" style={{display:(props.size===0?"none":"block"),}} >
      <div className="RoundedButton" onClick={props.handler}>
        <img src="imgs/icons/arrow-icon.svg" alt="Icono flecha"/>
      </div>
      <div className="TextoResultados"><span>{props.size+" "} </span></div>
    </div>

  )
}
class App extends React.Component {
  state = {page:0,manual:false,loginBody:false,searchText:"",background:false,}
  //myPageHandler = this.myPageHandler.bind(this);
  //myManualHandler = this.myManualHandler.bind(this);
  miestilo={backgroundImage: "url(imgs/background/doodles.png)", backgroundRepeat:"repeat",};

  myPageHandler = (val=1) => () => {
    this.setState({page:this.state.page+val});
  }
  myManualHandler = () => {
    this.setState({manual:!this.state.manual});
  }
  myLoginHandler = () => {
    this.setState({loginBody:!this.state.loginBody,background:Tools.background(),});
  }
  
  setSearchText = (val) => {
    this.setState({searchText:val});
    console.log ( val);
  }
  setAllText =(val) => {
    this.setState({searchText:"*"});
    console.log ( val);
  }
  mySearchHandler = (button=false) => button?Tools.searchTags(this.setAllText):Tools.searchTags(this.setSearchText);

  //componentWill mount is depreciated, perhaps use the constructor ,.statefromprops, ..willupdate
  // better constructor but i like willMount cause is more explicit. .props no good as is suposed to be static
  // willupdate is ok, but anyway props and willupdate we have already calculated the dom tree, although not rendered
  componentWillMount(){Tools.importAll();} 

  render() {
    switch (this.state.page){
      case 0: //Initial Animation
        return (
          <div className="App">
            <InitAnimation handler={this.myPageHandler()}/>
          </div>
        );
      case 1://Main Page    <Login display={this.state.login} handler={Tools.getUserConfig}/>
          return (
          <div className="App" style={this.state.background?this.miestilo:{}}>
            <Logo />
            <Search page={this.state.page} handler={this.myPageHandler()}/>
            <Lema handler={this.myManualHandler}/>
            <Manual show={this.state.manual} handler={this.myManualHandler}/>
            <Login display={this.state.loginBody} handlerType={this.myLoginHandler} handler={[Tools.getUserConfig,Tools.registerUser]}/>
          </div>
        );
      case 2: //Search Page      style={{background: '#242F3A',}} Tools.getSelProducts();
        return (
          <div className="App" style={this.state.background?this.miestilo:{}}>
            <Search page={this.state.page} handler={this.mySearchHandler}/>
            <TagsBuscadorContainer searchText={this.state.searchText}/>
            <VerResultados size={Tools.selected.on.TagProducto.size} handler={() => Tools.getSelProducts(this.myPageHandler())}/>
            <BuscadorOverlay />
            <Logo />
            <Login display={this.state.loginBody} handlerType={this.myLoginHandler} handler={[Tools.getUserConfig,Tools.registerUser]}/>
          </div>
        );
      case 3: //Results Page                       style={{background: '#242F3A',}}
        return (
          <div className="App" style={this.state.background?this.miestilo:{}}>
            <Resultados />
            <VerResultados size={Tools.selected.on.TagProducto.size} handler={() => Tools.getSelProducts(this.myPageHandler(-1))}/>
            <BuscadorOverlay />
            <Logo />
          </div>
        );
      default:break;
    }
  }
}
export default App;