import React from 'react';
import Tools from './Tools';

const flagByName = (name) => <div style={{width:'24px',height:'24px',}}><img className={"TagFlag flag flag-"+name} src="css/flags/blank.gif" alt="bandera"/></div> ;
const renderProduct = (server) => (row,index) => {
  let item=row.split("|");
  return (
    <div className="Tarjeta" key={item[0]}> 
      <div className="Producto" > 
        <img src={server+item[8]+item[9]} alt="imagen no disponible"></img>
      </div>
      <div className="Titulo">{item[1]}</div>
      <div className="Origen">
        <div className="Detalle" style={{display:Tools.logged?"block":"none"}}><div>Origen:</div>{flagByName(item[2])}</div>
        <div className="Detalle"><div>Capital:</div>{flagByName(item[3])}</div>
      </div>
    </div>
  );
}

class Resultados extends React.Component {
  state ={};//searchText:"",

  resultados = [];

  render(){
    this.resultados = Tools.productsSelected.map(renderProduct(Tools.serverUrl));
    return ( 
      <div className="Resultados">
        {this.resultados}
      </div>
    );
    
  }
}

export default Resultados;