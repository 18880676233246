import React from 'react';
//import Tools from './Tools';

const Login = (props) => {
  return (
    <div className="Login" style={{height:props.display?"21%":"7%"}}>
      <div className="RoundedButton" onClick={props.handlerType}>
        <img src="imgs/icons/profile-icon.svg" alt="Icono flecha"/>
      </div>

      <div className="LoginBody" style={{display:props.display?"block":"none"}}>
        <input id="inputMail" type="text" placeholder="Mail" /> 
        <input id="inputPassword" type="password" placeholder="Password" /> 
        <br></br>
        <button onClick={()=>props.handler[0](props.handlerType)}>Login</button>
        <button onClick={()=>props.handler[1](props.handlerType)}>Register</button>
      </div>
    </div>
  )
}

export default Login;